export const switchData = [
	{
		label: 'Fast Brain',
		value: 'fast_brain',
		checked: true,
	},
	{
		label: 'Slow Brain',
		value: 'slow_brain',
		checked: true,
	},
	{
		label: 'Risk Assessment Questionnaire',
		value: 'risk_assessment',
		checked: true,
	},
	{
		label: 'Portfolio Recommendations',
		value: 'recommendation',
		checked: true,
	},
];
