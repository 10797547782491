import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import GlobalCssPriority from './css-priority/plain-css-priority';
import PlainCssPriority from './css-priority/global-css-priority';
import theme from './theme.config';

const Theme: React.FC<{ children: React.ReactNode }> = ({ children }): React.ReactElement => (
	<GlobalCssPriority>
		<PlainCssPriority>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</PlainCssPriority>
	</GlobalCssPriority>
);

export default Theme;
