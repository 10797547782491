import { FunctionComponent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { useBlocker, useOutletContext } from 'react-router-dom';
import { MDXEditorMethods } from '@mdxeditor/editor';
import { Client } from '@/app/api/clients';
import {
	AiOutputEditor,
	AiOutputLeaveDialog,
	AiOutputSection,
	AI_OUTPUT_LEAVE_EDIT_DIALOG_CONTENT,
	AI_OUTPUT_LEAVE_TAB_DIALOG_CONTENT,
} from '@/shared/components/ai-output';
import { AiOutputChunk } from '@/shared/types/ai-output';
import {
	useNextBestActionsAiOutput,
	useUpdateNextBestActionsAiOutput,
} from './next-best-actions.queries';

const HeadingDivider = styled(Divider)({
	marginTop: '1.5rem',
	marginBottom: '2rem',
});

const NextBestActionsPage: FunctionComponent = (): ReactElement => {
	const [chunks, setChunks] = useState<AiOutputChunk[]>([]);
	const [editChunk, setEditChunk] = useState<AiOutputChunk | null>(null);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const editorRef = useRef<MDXEditorMethods>(null);

	const client = useOutletContext<Client>();
	const { data } = useNextBestActionsAiOutput(client.id);

	// Define cases when user should not be able to submit the edited output
	const isSaveDisabled = useMemo(() => !editChunk?.content.length, [editChunk]);

	const [isLeaveEditDialogOpen, setIsLeaveEditDialogOpen] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { state, proceed, reset } = useBlocker(isDirty);

	useEffect(() => {
		const { content: updatedContent, index: updatedIndex } = editChunk || {};
		const originalContent = data?.sections.find((_, index) => index === updatedIndex);
		setIsDirty(originalContent !== updatedContent);
	}, [editChunk, data]);

	const initialChunks = useMemo(() => {
		const { sections = [] } = data || {};
		return sections.map((section, index) => ({ index, content: section }));
	}, [data]);

	useEffect(() => {
		setChunks(initialChunks);
	}, [initialChunks]);

	const { mutate: updateNextBestActionsAiOutput, isPending: isSubmitting } =
		useUpdateNextBestActionsAiOutput();

	const onSubmit = () => {
		if (!editChunk || isSaveDisabled) return;

		const editedSections = [
			...chunks.slice(0, editChunk.index),
			editChunk,
			...chunks.slice(editChunk.index),
		];
		const sections = editedSections.map((chunk) => chunk.content);
		const payload = { sections };

		updateNextBestActionsAiOutput(
			{ clientId: client.id, payload },
			{
				onSuccess: () => {
					setEditChunk(null);
					setChunks(initialChunks);
					proceed?.();
				},
			}
		);
	};

	const onEditChunk = (chunk: AiOutputChunk) => {
		const updatedChunks = [...chunks];
		updatedChunks.splice(chunk.index, 1);

		setChunks(updatedChunks);
		setEditChunk(chunk);
	};

	const onChange = (markdown: string) => {
		if (editChunk) {
			setEditChunk({ ...editChunk, content: markdown });
		}
	};

	const onCancelEditConfirm = () => {
		setChunks(initialChunks);
		setIsLeaveEditDialogOpen(false);
		setEditChunk(null);
	};

	const onCancelEdit = () => {
		if (isSaveDisabled) {
			setChunks(initialChunks);
			setEditChunk(null);
		} else {
			setIsLeaveEditDialogOpen(true);
		}
	};

	return (
		<Box component={Paper} padding="1.5rem" elevation={1}>
			<Typography component="h1" variant="h5">
				Next Best Actions
			</Typography>
			<HeadingDivider />
			<Stack direction="column" gap="0.5rem">
				<AiOutputEditor
					ref={editorRef}
					chunk={editChunk}
					onChange={onChange}
					onCancel={onCancelEdit}
					onConfirm={onSubmit}
					isSubmitting={isSubmitting}
					isDisabled={isSaveDisabled}
				/>

				<Stack direction="column" gap="0.5rem">
					{chunks.map((chunk) => (
						<AiOutputSection
							key={chunk.index}
							markdown={chunk.content}
							onEdit={() => onEditChunk(chunk)}
						/>
					))}
				</Stack>

				<AiOutputLeaveDialog
					content={AI_OUTPUT_LEAVE_TAB_DIALOG_CONTENT}
					open={state === 'blocked'}
					onClose={() => reset?.()}
					onSubmit={onSubmit}
					isSubmitting={isSubmitting}
				/>
				<AiOutputLeaveDialog
					content={AI_OUTPUT_LEAVE_EDIT_DIALOG_CONTENT}
					open={isLeaveEditDialogOpen}
					onClose={onCancelEditConfirm}
					onSubmit={onSubmit}
					isSubmitting={isSubmitting}
				/>
			</Stack>
		</Box>
	);
};

export default NextBestActionsPage;
