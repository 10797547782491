export const toKebabCase = (str: string): string => {
	return str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();
};

export const copyLink = (text: string) => {
	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	navigator.clipboard.writeText(text);
};
