import { Navigate, RouteObject } from 'react-router-dom';
import { ClientsPage } from '@/clients';
import {
	ClientPage,
	DialoguesPage,
	DialoguesOverviewPage,
	FastBrainPage,
	FastBrainAiOutputPage,
	FastBrainTileSelectionPage,
	SlowBrainPage,
	SlowBrainAiOutputPage,
	SlowBrainTileSelectionPage,
	RiskAssessmentPage,
	RiskAssessmentAiOutputPage,
	RiskAssessmentTileSelectionPage,
	NextBestActionsPage,
} from '@/client';

export const routes: RouteObject[] = [
	{
		path: '/clients',
		element: <ClientsPage />,
	},
	{
		path: '/clients/:id',
		element: <ClientPage />,
		children: [
			{
				path: '',
				index: true,
				element: <Navigate to="dialogues" />,
			},
			{
				path: 'dialogues',
				element: <DialoguesPage />,
				children: [
					{
						path: '',
						index: true,
						element: <Navigate to="overview" />,
					},
					{
						path: 'overview',
						element: <DialoguesOverviewPage />,
					},
					{
						path: 'fast-brain',
						element: <FastBrainPage />,
						children: [
							{
								path: '',
								index: true,
								element: <Navigate to="tile-selection" />,
							},
							{
								path: 'tile-selection',
								element: <FastBrainTileSelectionPage />,
							},
							{
								path: 'ai-output',
								element: <FastBrainAiOutputPage />,
							},
						],
					},
					{
						path: 'slow-brain',
						element: <SlowBrainPage />,
						children: [
							{
								path: '',
								index: true,
								element: <Navigate to="tile-selection" />,
							},
							{
								path: 'tile-selection',
								element: <SlowBrainTileSelectionPage />,
							},
							{
								path: 'ai-output',
								element: <SlowBrainAiOutputPage />,
							},
						],
					},
					{
						path: 'risk-assessment-questionnaire',
						element: <RiskAssessmentPage />,
						children: [
							{
								path: '',
								index: true,
								element: <Navigate to="tile-selection" />,
							},
							{
								path: 'tile-selection',
								element: <RiskAssessmentTileSelectionPage />,
							},
							{
								path: 'ai-output',
								element: <RiskAssessmentAiOutputPage />,
							},
						],
					},
				],
			},
			{
				path: 'life-plan',
				element: <>Life plan tab</>,
			},
			{
				path: 'portfolio-recommendations',
				element: <>Portfolio Recommendation</>,
			},
			{
				path: 'next-best-actions',
				element: <NextBestActionsPage />,
			},
		],
	},
];
