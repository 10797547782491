import axios from 'axios';

const { VITE_BASE_API_URL, VITE_OCP_APIM_SUBSCRIPTION_KEY, VITE_ADVISOR_UUID } = import.meta.env;
const BASE_API_URL = VITE_BASE_API_URL as string;
const OCP_APIM_SUBSCRIPTION_KEY = VITE_OCP_APIM_SUBSCRIPTION_KEY as string;
const ADVISOR_UUID = VITE_ADVISOR_UUID as string;

export const httpClient = axios.create({
	baseURL: BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Ocp-Apim-Subscription-Key': OCP_APIM_SUBSCRIPTION_KEY,
		'Advisor-Uuid': ADVISOR_UUID,
	},
});
