import React from 'react';
import {
	TableHead as MuiTableHead,
	TableRow as MuiTableRow,
	TableCell as MuiTableCell,
} from '@mui/material';
import { Column } from '../table.types';

interface TableHeadProps<Data> {
	columns: Column<Data>[];
}

const TableHead = <Data,>({ columns }: TableHeadProps<Data>): React.ReactElement => (
	<MuiTableHead>
		<MuiTableRow>
			{columns.map(({ name, align }) => (
				<MuiTableCell align={align || 'inherit'} key={name}>
					{name}
				</MuiTableCell>
			))}
		</MuiTableRow>
	</MuiTableHead>
);

export default TableHead;
