import {
	TableProps as MuiTableProps,
	TableContainer as MuiTableContainer,
	Table as MuiTable,
	TableBody as MuiTableBody,
	Stack,
} from '@mui/material';
import { Column, Pagination } from './table.types';
import { TableHead } from './table-head';
import { TableRow } from './table-row';
import { TablePagination } from './table-pagination';

export interface TableProps<Data> {
	columns: Column<Data>[];
	rows: Data[];
	ariaLabel?: string;
	onRowClick?: (row: Data) => void;
	pagination: Pagination;
}

const Table = <Data,>({
	rows,
	columns,
	ariaLabel,
	onRowClick,
	pagination,
	...rest
}: TableProps<Data> & MuiTableProps): React.ReactElement => {
	const DEFAULT_TABLE_ARIA_LABEL = 'Tabular data representation';

	return (
		<Stack direction="column">
			<MuiTableContainer {...rest}>
				<MuiTable aria-label={ariaLabel || DEFAULT_TABLE_ARIA_LABEL}>
					<TableHead columns={columns} />
					<MuiTableBody>
						{rows.map((row, index) => (
							<TableRow
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								columns={columns}
								data={row}
								rowAction={() => onRowClick?.(row)}
							/>
						))}
					</MuiTableBody>
				</MuiTable>
			</MuiTableContainer>
			<TablePagination {...pagination} />
		</Stack>
	);
};

export default Table;
